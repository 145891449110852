export default ({ data }: any) => {
    return (
        <>
            {data.seller == null && 
                <section id="body">
                    <div className="h-100 d-flex">
                        <div className="container d-flex">
                            <div className="row m-auto justify-content-center">
                                <div className="col-12 col-sm-5 mb-5">
                                    <img src="/images/maintenance.png" className="img-fluid"/>
                                </div>
                                {data.message != '' && 
                                    <div className="col-12 mb-3 d-flex">
                                        <div className="content-block">
                                            <h4 className="text-justify text-sm-center">{ data.message }</h4>
                                        </div>
                                    </div>
                                }
                                {data.title != '' && 
                                    <div className="col-12 mb-3 d-flex">
                                        <div className="content-block">
                                            <h4 className="text-justify text-sm-center">{ data.title }</h4>
                                        </div>
                                    </div>
                                }
                                {data.desc != '' && 
                                    <div className="col-12 d-flex">
                                        <div className="content-block">
                                            <p className="text-justify text-sm-center">{ data.desc }</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}