import config from "../config/default.json";
import axios from "axios";

export const getStoreData = async (domain: any) => {
    let code: string = domain.split('.')[0];
    if(code == null || process.env.NODE_ENV == 'development') code = config.developmentStoreCode;

    try {
        let response: any = await axios.post(config.apiUrl + 'app', {}, {
            headers: {
                'store-code': code
            }
        })
        return response.data.data;
    } catch(error: any) {
        return error.response.data.data;
    }
}