import React from 'react';
import './base/assets/css/page.css';
import 'bootstrap/dist/css/bootstrap.css';
import './base/assets/vendor/font-awesome/css/font-awesome.min.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getStoreData } from './base';
import Store from './pages/store';
import PrivacyPolicy from './pages/privacy_policy';
import { Routes, Route } from "react-router-dom";
import Maintenance from './components/maintenance';
import RefundPolicy from './pages/refund_policy';
import TermsOfUsage from './pages/terms_of_usage';

function App() {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let result: any = await getStoreData(window.location.hostname);
            if(result) {
                if(document != null) {
                    document.title = result.seller.name;
                }
                setData(result);
            }
        }
        fetchData(); 
    }, [])

    return (
        <>
            {data != null && <> 
                <Routes>
                    <Route path="/" element={<Store data={data} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy data={data} />} />
                    <Route path="/refund-policy" element={<RefundPolicy data={data} />} />
                    <Route path="/terms-of-usage" element={<TermsOfUsage data={data} />} />
                </Routes>
                <Maintenance data={data}/>
            </>}
        </>
    );
}

export default App;
