export default ({ data }: any) => {
    const getAddress = () => {
        let address: string = '';
        address += (data.seller.address.address_line_1 != '') ? data.seller.address.address_line_1+', ' : '';
        address += (data.seller.address.address_line_2 != '') ? data.seller.address.address_line_2+', ' : '';
        address += (data.seller.address.area != '') ? data.seller.address.area+', ' : '';
        address += (data.seller.address.city != '') ? data.seller.address.city+', ' : '';
        address += (data.seller.address.state != null) ? data.seller.address.state.name+', ' : '';
        address += (data.seller.address.country != null) ? data.seller.address.country.name : '';
        address += (data.seller.address.pincode != '') ?  ' - '+ data.seller.address.pincode : '';
        return address;
    }
    return <>
        {data.seller != null &&
            <section>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h4>Return and Refund Policy</h4>
                            <p className="text-justify">Thank you for shopping at { data.seller.name }.</p>
                            <p className="text-justify">If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns.</p>
                            <p className="text-justify">The following terms are applicable for any products that You purchased with Us.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Interpretation and Definitions</h4>
                            <p className="text-justify mt-2"><b>Interpretation</b></p>
                            <p className="text-justify">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                            <p className="text-justify mt-2"><b>Definitions</b></p>
                            <p className="text-justify">For the purposes of this Return and Refund Policy:</p>
                            <ul className="my-2">
                                <li>Company (referred to as either &apos;the Company&apos;, &apos;We&apos;, &apos;Us&apos; or &apos;Our&apos; in this Agreement) refers to { data.seller.name }, {getAddress()}.</li>
                                <li>Goods refer to the items offered for sale.</li>
                                <li>Orders mean a request by You to purchase Goods from Us.</li>
                                <li>Service refers to the Website.</li>
                                <li>Website refers to { data.seller.name }, accessible from { data.seller.website }</li>
                                <li>You mean the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                            </ul>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Your Order Cancellation Rights</h4>
                            <p className="text-justify">You are entitled to cancel Your Order within 7 days without giving any reason for doing so.</p> 
                            <p className="text-justify">The deadline for cancelling an Order is 7 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p> 
                            <p className="text-justify">In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p> 
                            <ul className="my-2">
                                {data.seller.email_id != '' && <li>By email: { data.seller.email_id }</li> }
                                <li>By visiting this page on our website: { data.seller.website }</li>
                            </ul>
                            <p className="text-justify">We will reimburse You no later than 7 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Conditions for Returns</h4>
                            <p className="text-justify">In order for the Goods to be eligible for a return, please make sure that:</p>
                            <ul className="my-2">
                                <li>The Goods were purchased in the last 7 days</li>
                                <li>The Goods are in the original packaging</li>
                            </ul>
                            <p className="text-justify">The following Goods cannot be returned:</p>
                            <ul className="my-2">
                                <li>The supply of Goods made to Your specifications or clearly personalised.</li>
                                <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
                                <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
                            </ul>
                            <p className="text-justify">We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Returning Goods</h4>
                            <p className="text-justify">You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
                            <p className="text-justify">{getAddress()}</p>
                            <p className="text-justify">We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Refunds</h4>
                            <p className="text-justify">After receiving your return and inspecting the condition of your item, we will process your return. Please allow at least 3 days from the receipt of your item to process your return. Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company. We will notify you by email when your return has been processed.</p>
                        </div>
                        <div className="col-12">
                            <h4>Contact Us</h4>
                            <p className="text-justify">If you have any questions about our Returns and Refunds Policy, please contact us:</p>
                            <ul className="my-2">
                                {data.seller.email_id != '' && <li>By email: { data.seller.email_id }</li> }
                                <li>By visiting this page on our website: { data.seller.website }</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>  
        }
    </>
}