import { useEffect } from "react";
import { Link } from "react-router-dom";

export default ({ data }: any) => {
    
    useEffect(() => {
        const getMobileOperatingSystem = () => {
            // @ts-ignore
            let userAgent: any = navigator.userAgent || navigator.vendor || window.opera;
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }
            if (/android/i.test(userAgent)) {
                return "Android";
            }
            // @ts-ignore
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            return "unknown";
        }
    
        const openAppUrl = () => {
            let deviceType: any = getMobileOperatingSystem();
            if(deviceType == 'Android' && data != null && data.android_app_url != null && data.android_app_url != '') {
                window.location.href = data.android_app_url;
            
            } else if(deviceType == 'iOS' && data != null && data.apple_app_url != null && data.apple_app_url != '') {
                window.location.href = data.apple_app_url;
            } 
        }
    
        const init = () => {
            if(data != null) {
                let urlString: any = window.location.href; 
                let url: any = new URL(urlString);
                let product: any = url.searchParams.get("p");
                let category: any = url.searchParams.get("c");
                let order: any = url.searchParams.get("o");
                if(product != null)  {
                    let appOpen: any = window.location.replace(data.code+'://p='+product);
                    if(typeof appOpen === "undefined") openAppUrl(); 
                    
                } else if(category != null) {
                    let appOpen: any = window.location.replace(data.code+'://c='+category);
                    if(typeof appOpen === "undefined") openAppUrl();   
                    
                } else if(order != null) {
                    let appOpen: any = window.location.replace(data.code+'://o='+order);
                    if(typeof appOpen === "undefined") openAppUrl(); 
                    
                }
            }
        }
        init();
    }, []);
    
    return <>
        {data.seller != null &&
            <>
                <section id="body">
                    <div className="h-100 d-flex">
                        <div className="container d-flex">
                            <div className="row m-auto">
                                <div className="col-12">
                                    <div className="body-logo-block">
                                        {data.seller.logo != '' && <img src={ data.seller.logo_image_url + data.seller.logo } className="img-fluid"/> }
                                        {data.seller.logo == '' && <img src="/images/logo.png" /> }
                                    </div>
                                </div>
                                <div className="col-12 title-block text-center mt-4 mb-4">
                                    <p>{  data.seller.name }</p>
                                    <div className="title-line mx-auto"></div>
                                </div>
                                {data.seller.company_overview != '' && 
                                    <div className="col-12 mb-4 d-flex">
                                        <div className="content-block">
                                            <p className="text-justify text-sm-center">{ data.seller.company_overview }</p>
                                        </div>
                                    </div>
                                }
                                <div className="col-12 d-md-flex mb-4 justify-content-center">
                                    {data.seller.app_contact.whatsapp_country_no != '' && 
                                        <a className="info-block py-2 p-lg-2" 
                                            href={'https://api.whatsapp.com/send?phone='+data.seller.app_contact.whatsapp_country_code + data.seller.app_contact.whatsapp_contact_no} target="_blank" rel="noopener noreferrer">
                                            <div className="info-icon">
                                                <i className="fa fa-whatsapp"></i>
                                            </div>
                                            <div className="info-details">
                                                <p>WhatsApp</p>
                                                <span>{ '+'+data.seller.app_contact.whatsapp_country_code + data.seller.app_contact.whatsapp_contact_no }</span>
                                            </div>
                                        </a>
                                    }   
                                    {data.seller.phone_no != '' && 
                                        <a className="info-block py-2 p-lg-2" href={'tel:+'+data.seller.phone_no} target="_blank" rel="noopener noreferrer">
                                            <div className="info-icon">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                            <div className="info-details">
                                                <p>Call Us</p>
                                                <span>{ '+91'+data.seller.phone_no }</span>
                                            </div>
                                        </a>
                                    }   
                                    {data.seller.email_id != '' && 
                                        <a className="info-block py-2 p-lg-2" href={'mailto:'+data.seller.email_id} target="_blank" rel="noopener noreferrer">
                                            <div className="info-icon">
                                                <i className="fa fa-envelope"></i>
                                            </div>
                                            <div className="info-details">
                                                <p>Email</p>
                                                <span>{ data.seller.email_id }</span>
                                            </div>
                                        </a>
                                    }   
                                </div>
                                <div className="col-12 d-sm-flex mb-4 justify-content-center">
                                    { data.android_app_url != '' && 
                                        <a href={data.android_app_url} target="_blank" rel="noopener noreferrer">
                                            <div className="app-icon-block">
                                                <img src="/images/play-store.png" className="img-fluid" title={data.seller.name} alt={data.seller.name} />
                                            </div>
                                        </a>
                                    }
                                    { data.apple_app_url != '' && 
                                        <a href={data.apple_app_url} target="_blank" rel="noopener noreferrer">
                                            <div className="app-icon-block">
                                                <img src="/images/app-store.png" className="img-fluid" title={data.seller.name} alt={data.seller.name} />
                                            </div>
                                        </a>
                                    }
                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <Link to="privacy-policy">
                                        <span className="color-blue">Privacy Policy</span>
                                    </Link>
                                    <span className="mx-2">|</span>
                                    <Link to="refund-policy">
                                        <span className="color-blue">Refund Policy</span>
                                    </Link>
                                    <span className="mx-2">|</span>
                                    <Link to="terms-of-usage">
                                        <span className="color-blue">Terms of Usage</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="copy-right-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 justify-content-center justify-content-lg-start d-flex">
                                <span className="my-auto">{ '© '+data.seller.name+' | All rights reserved' }</span>
                            </div>
                            <div className="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                                <span className="my-auto">Developed by</span>
                                <a href={data.developer_url} target="blank">
                                    <img className="sellon-logo" src={data.developer_logo} alt="SellOn App" title="SellOn App" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        }
    </>
};
