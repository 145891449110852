export default ({ data }: any) => {
    return <>
        {data.seller != null &&
            <section>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h4>Privacy Policy for {data.seller.name }</h4>
                            <p className="text-justify">At { data.seller.name }, accessible from { data.seller.website }, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by { data.seller.name } and how we use it.</p>
                            <p className="text-justify">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
                            <p className="text-justify">This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in { data.seller.name }. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Consent</h4>
                            <p className="text-justify">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Information we collect</h4>
                            <p className="text-justify">The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                            <p className="text-justify">If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                            <p className="text-justify">When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                            <p className="text-justify">In order to secure the business&apos;s sensitive and unique data including information about prices, designs, product details, it is essential to signup or login. We are manufacturer and wholesaler, so we do not allow unregistered users to access our app.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Permission we ask for App Usage</h4>
                            <p className="text-justify">We need the file read/write permission to download our product images and notification permission to get alert of new arrival products.</p>
                            <p className="text-justify">We need Photo Library Access Permission to share product photos.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Payment Policy</h4>
                            <p className="text-justify">We are using a third party payment gateway service to collect the payment. So, we never save the payment information like the bank details, the card details in our database.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>How we use your information</h4>
                            <p className="text-justify">We use the information we collect in various ways, including to:</p>
                            <ul className="my-2">
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ul>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Log Files</h4>
                            <p className="text-justify">{ data.seller.name } follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as a part of hosting services analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users&apos; movement on the website, and gathering demographic information.</p>
                        </div>
                        <div className="col-12 mb-3">
                            <h4>Cookies and Web Beacons</h4>
                            <p className="text-justify">Like any other website, { data.seller.name } uses &apos;cookies&apos;. These cookies are used to store information including visitors&apos; preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the user&apos;s experience by customizing our web page content based on visitors&apos; browser type and/or other information.</p>
                        </div>
                        <div className="col-12">
                            <h4 className="mb-3">How to Delete Your Data on { data.seller.name } App</h4>
                            <p className="text-justify"><b>Introduction:</b></p>
                            <p className="text-justify">At { data.seller.name }, we are committed to ensuring your privacy and providing you with control over your personal data. To comply with Google Play Store or App Store policy and enhance transparency, we've outlined a simple guide on how to delete your data from our app.</p>
                            <p className="text-justify mt-3"><b>Step-by-Step Guide:</b></p>
                            <ul className="mt-2 mb-3">
                                <li>
                                    <p className="text-justify">Open { data.seller.name } App:</p>
                                    <p className="text-justify">Launch the { data.seller.name } application on your device.</p>
                                </li>
                                <li>
                                    <p className="text-justify">Navigate to Profile:</p>
                                    <p className="text-justify">Locate the "Profile" section within the app. This is typically represented by a menu icon in app top left on side.</p>
                                </li>
                                <li>
                                    <p className="text-justify">Find Delete Data Option:</p>
                                    <p className="text-justify">Find the delete data section at the bottom of the profile screen.</p>
                                </li>
                                <li>
                                    <p className="text-justify">Confirm Deletion:</p>
                                    <p className="text-justify">Once you've found the option to delete data, the app may ask you to confirm your decision. Follow the on-screen prompts to confirm the deletion of your data.</p>
                                </li>
                            </ul>
                            <p className="text-justify">Thank you for choosing { data.seller.name } app. We value your privacy and strive to provide you with a secure and transparent user experience.</p>
                        </div>
                    </div>
                </div>
            </section> 
        }
    </>
}